import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
Vue.use(VueRouter);
function isLoggedIn(to, from, next) {
    store.dispatch('auth/authenticate').then(() => {
        next();
    }).catch(() => {
        next('/login');
    });
}
const routes = [
    {
        path: '/',
        component: () => import('../views/Home.vue'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '',
                name: 'FeedSingle',
                component: () => import('../views/FeedList.vue'),
            },
            {
                path: 'users',
                name: 'Users',
                component: () => import('../views/Users.vue'),
            },
            {
                path: 'schedule-logs',
                name: 'logs',
                component: () => import('../views/Logs.vue'),
            },
            {
                path: 'queue',
                name: 'queue',
                component: () => import('../views/Queue/QueueList.vue'),
            },
            {
                path: 'settings',
                name: 'Settings',
                component: () => import('../views/Settings.vue'),
            },
            {
                path: 'support',
                name: 'Support',
                component: () => import('../views/Support.vue'),
            },
        ]
    },
    {
        path: '/feed',
        component: () => import('../views/FeedSingle/Index.vue'),
        beforeEnter: isLoggedIn,
        children: [
            {
                path: '/feed/:id',
                component: () => import('../views/Dashboard.vue'),
                props: true,
                children: [
                    {
                        path: '',
                        name: 'PropertyDetails',
                        component: () => import('../views/FeedSingle/PropertyDetails.vue'),
                    },
                    {
                        path: 'query-management',
                        name: 'QueryManagement',
                        component: () => import('../views/FeedSingle/QueryManagement.vue'),
                    },
                    {
                        path: 'scheduled-tasks',
                        name: 'ScheduledTasks',
                        component: () => import('../views/FeedSingle/ScheduledTasks.vue'),
                    },
                    {
                        path: 'schedule-logs',
                        name: 'feed.logs',
                        component: () => import('../views/Logs.vue'),
                    },
                    {
                        path: 'runs',
                        name: 'feed.runs',
                        component: () => import('../views/FeedSingle/Runs/Index.vue'),
                    },
                    {
                        path: 'runs/:runId',
                        name: 'feed.runDetails',
                        component: () => import('../views/FeedSingle/Runs/RunDetails.vue'),
                        props: true,
                    },
                    {
                        path: 'ftp',
                        name: 'FTP',
                        component: () => import('../views/FeedSingle/FTP.vue'),
                    },
                    {
                        path: 'extra-fields',
                        name: 'feed.extra-fields',
                        component: () => import('../views/FeedSingle/ExtraFields/ExtraFieldsSettings.vue'),
                    },
                ]
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
    },
];
const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
});
export default router;

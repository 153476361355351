// src/store/services/users.js
import feathersClient, { BaseModel, makeServicePlugin } from '../../feathers-client';
import { alterItems } from 'feathers-hooks-common';
export class RunLogs extends BaseModel {
    constructor(data, options) {
        super(data, options);
    }
    // Required for $FeathersVuex plugin to work after production transpile.
    static modelName = 'RunLog';
    // Define default properties here
    static instanceDefaults() {
        return {
            message: '',
            level: 'info',
            runId: '',
            date: new Date(),
        };
    }
}
const servicePath = 'run-log';
const servicePlugin = makeServicePlugin({
    Model: RunLogs,
    service: feathersClient.service(servicePath),
    servicePath,
});
// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
    before: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
    },
    after: {
        all: [
            alterItems((item) => {
                item.date = new Date(item.date);
            }),
        ],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
    },
    error: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
    },
});
export default servicePlugin;
